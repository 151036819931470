import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/pages/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/pages/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/pages/login')
             return Promise.reject(error)
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/pages/login')
           return Promise.reject(error)
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIClientConnected {

  // ---------------- CLIENT ------------------------------
  connectedClientGetProfile(token) {
    const url = apiBaseUrl + '/connected-client-detail'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  connectedClientModifyProfile (token, is_firm, siren, forme_sociale, representant_legal, name, first_name,
    last_name, email, phone_country_code, phone_number,
    is_domiciliation, address, is_different_payeur, is_payeur_firm, payeur_siren, payeur_representant_legal,
    payeur_name, payeur_address) {
    const url = apiBaseUrl + '/connected-client-detail'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'is_firm': is_firm,
      'siren': siren,
      'forme_sociale': forme_sociale,
      'representant_legal': representant_legal,
      'name': name,
      'first_name': first_name,
      'last_name': last_name,
      'email': email,
      'is_domiciliation': is_domiciliation,
      'address': address,
      'is_different_payeur': is_different_payeur,
      'is_payeur_firm': is_payeur_firm,
      'payeur_siren': payeur_siren,
      'payeur_representant_legal': payeur_representant_legal,
      'payeur_name': payeur_name,
      'payeur_address': payeur_address
    }
    if (phone_number) {
      data['phone_country_code'] = phone_country_code
      data['phone_number'] = phone_number
    }
    else {
      data['phone_country_code'] = ''
      data['phone_number'] = ''
    }
    return axios_instance.put(
      url, data ,
      {
        'headers': headers,
      }
    )
  }

  // ---------------- MISSIONS ----------------------------

  getConnectedClientAllMissions(token) {
    const url = apiBaseUrl + '/get-connected-client-all-missions'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getConnectedClientMission(token, pk) {
    const url = apiBaseUrl + `/connected-client-get-mission/` + pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  // ---------------- PRIORITES ----------------------------

  getConnectedClientMissionAllPriorities (token, mission_pk) {
    const url = apiBaseUrl + `/connected-client-mission-all-priorities/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getConnectedClientMissionAllInactivePriorities (token, mission_pk) {
    const url = apiBaseUrl + `/connected-client-mission-all-inactive-priorities/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }



  // ---------------- FACTURES ---------------------------

  getConnectedClientAllFactures(token) {
    const url = apiBaseUrl + '/connected-client-get-all-factures'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getConnectedClientMissionAllFactures(token, mission_pk) {
    const url = apiBaseUrl + `/connected-client-mission-all-factures/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  getConnectedClientFacture(token, facture_pk) {
    const url = apiBaseUrl + `/connected-client-get-facture/` + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getConnectedClientAllFacturePaiements(token, facture_pk) {
    const url = apiBaseUrl + `/connected-client-all-paiements/` + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  connectedClientDownloadFacture (token, facture_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/connected-client-download-facture/' + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  connectedClientDownloadPaiement (token, paiement_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/connected-client-download-paiement/' + paiement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }


  // -------------- DOCUMENTS ASKINGS ---------------
  connectedClientAllNotValidatedDocumentAskings(token) {
    const url = apiBaseUrl + '/connected-client-all-not-validated-document-askings'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  connectedClientGetDocumentAsking(token, document_asking_pk) {
    const url = apiBaseUrl + `/connected-client-get-document-asking/` + document_asking_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  connectedClientMissionAllDocumentAskings(token, mission_pk) {
    const url = apiBaseUrl + `/connected-client-mission-all-document-askings/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }




  // ------------ DOCUMENTS ----------------------------
  connectedClientGetAllDocuments(token, document_asking_pk) {
    const url = apiBaseUrl + `/connected-client-get-all-documents/` + document_asking_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  createDocument (token, document_asking_pk, file) {
    const url = apiBaseUrl + `/connected-client-create-document`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('file', file);
    formData.append('document_asking', document_asking_pk);
    return axios_instance.post(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  modifyDocument (token, document_pk, file) {
    const url = apiBaseUrl + `/connected-client-modify-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('file', file);
    return axios_instance.put(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  deleteDocument (token, document_pk) {
    const url = apiBaseUrl + `/connected-client-delete-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  connectedClientDownloadOwnDocument (token, document_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/connected-client-download-document/' + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

}
