var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ClientDashboard"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true,"color":"black"},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('CRow',[_c('CCol',[_c('h1',[_vm._v(" Bienvenue sur votre espace client ")]),_c('p',[_vm._v(" Cet espace est mis en place par "),_c('em',[_vm._v(_vm._s(_vm.companyName))]),_vm._v(" pour vous permettre de suivre en temps réel vos missions et votre facturation."),_c('br'),_vm._v(" Il vous est également possible de charger des documents à la demande de votre cabinet. ")])])],1),_c('CRow',{staticClass:"font-dosis mt-3"},[_c('CCol',{attrs:{"sm":"3"}},[_c('CWidgetIcon',{attrs:{"text":"missions en cours","color":"primary","icon-padding":false}},[_c('strong',[_vm._v(_vm._s(_vm.missions.length))])])],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CWidgetIcon',{attrs:{"text":"demandes de documents en cours","color":"info","icon-padding":false}},[_c('strong',[_vm._v(_vm._s(_vm.nbDocsOnGoing))])])],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CWidgetIcon',{attrs:{"text":"factures à payer","color":"danger","icon-padding":false}},[_c('strong',[_vm._v(_vm._s(_vm.nbFacturesToPay))])])],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CWidgetIcon',{attrs:{"text":"reste à payer","color":"warning","icon-padding":false}},[_c('strong',[_vm._v(_vm._s(_vm.formatThousandSeparatorNumber(parseFloat(_vm.resteAPayer).toFixed(2)))+" €")])])],1)],1),_c('CCard',{staticClass:"mt-3"},[_c('CCardHeader',[_c('strong',[_vm._v("📄 Vos missions en cours")])]),_c('CCardBody',[_c('CDataTable',{staticClass:"cursor-table",attrs:{"hover":"","items":_vm.missionsRender,"fields":_vm.missionsRenderFields,"noItemsView":{ noResults: "Aucun résultat", noItems: "Aucun résultat" }},on:{"row-clicked":_vm.goToMissionPage},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"types",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},_vm._l((item.types),function(type){return _c('em',{key:type.id},[_vm._v(_vm._s(type.name)+" ")])}),0)]}},{key:"starting_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$dayjs(item.starting_date).format('D MMM YYYY'))+" ")])])]}},{key:"referent",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[_vm._v(" "+_vm._s(item.referent)+" ")])]}},{key:"collaborateurs",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},_vm._l((item.collaborateurs),function(collaborateur){return _c('em',{key:collaborateur.id},[_vm._v(_vm._s(collaborateur.full_name)+", ")])}),0)]}},{key:"no-items-view",fn:function(){return [_c('CRow',[_c('CCol',{staticClass:"text-center"},[_vm._v(" Aucune mission en cours ")])],1)]},proxy:true}])})],1)],1),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("📃 Demandes de documents en cours")])]),_c('CCardBody',[_c('CDataTable',{staticClass:"cursor-table",attrs:{"hover":"","items":_vm.documentAskingsRender,"fields":_vm.documentAskingsRenderFields,"noItemsView":{ noResults: "Aucun résultat", noItems: "Aucun résultat" }},on:{"row-clicked":_vm.goToDocumentAskingPage},scopedSlots:_vm._u([{key:"mission_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(item.mission_name)+" ")])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"state",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[_vm._v(" "+_vm._s(_vm.clientDocumentAskingsStateRender(item.state))+" ")])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('small',[_vm._v(_vm._s(item.description))])])]}},{key:"no-items-view",fn:function(){return [_c('CRow',[_c('CCol',{staticClass:"text-center"},[_vm._v(" Aucune demande de documents en cours ")])],1)]},proxy:true}])})],1)],1),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("💵 Vos factures")])]),_c('CCardBody',[_c('CDataTable',{staticClass:"cursor-table",attrs:{"hover":"","items":_vm.facturesRender,"fields":_vm.facturesRenderFields,"noItemsView":{ noResults: "Aucun résultat", noItems: "Aucun résultat" }},on:{"row-clicked":_vm.goToFacturePage},scopedSlots:_vm._u([{key:"mission_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_c('span',[_vm._v(" "+_vm._s(item.mission_name)+" ")])])]}},{key:"date_facturation",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$dayjs(item.date_facturation).format('D MMM YYYY'))+" ")])])]}},{key:"date_echeance",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$dayjs(item.date_echeance).format('D MMM YYYY'))+" ")])])]}},{key:"montant_total_ht",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[_vm._v(" "+_vm._s(parseFloat(item.montant_total_ht).toFixed(2))+" € ")])]}},{key:"montant_total_ttc",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[_vm._v(" "+_vm._s(parseFloat(item.montant_total_ttc).toFixed(2))+" € ")])]}},{key:"montant_total_paid",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[_vm._v(" "+_vm._s(parseFloat(item.montant_total_paid).toFixed(2))+" € ")])]}},{key:"left_to_pay",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[_vm._v(" "+_vm._s(parseFloat(item.left_to_pay).toFixed(2))+" € ")])]}},{key:"no-items-view",fn:function(){return [_c('CRow',[_c('CCol',{staticClass:"text-center"},[_vm._v(" Aucune facture ")])],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }